<template>
    <div class="max-w-sm mx-auto mt-257 px-12 md:px-0">
        <Title
            :title="$t('resetPasswordCodeFormTitle')"
            :subtitle="$t('resetPasswordCodeFormSubtitle')"
        />

        <form
            @submit.prevent="onSubmit"
            ref="resetPasswordCodeForm"
        >
            <!-- SMS code input -->
            <Input
                v-on:input="onInput"
                class="mb-22"
                :label="$t('resetPasswordCodeFieldLabel')"
                :placeholder="$t('resetPasswordCodeFieldPlaceholder')"
                type="password"
                name="securityCode"
            />

            <p class="mb-37">
                <Counter @disabled="disabledExpiration = true"/>
            </p>

            <div class="w-full flex justify-center mb-36">
                <Button
                    class="button bg-purple-500 text-white hover:bg-purple-600"
                    :disabled="disabledExpiration ? disabledExpiration : disabled || resetPasswordValidateLoading"
                    role="submit"
                    :text="$t('resetPasswordCodeButtonText')"
                    :spinning="resetPasswordValidateLoading"
                />
            </div>
        </form>
    </div>
</template>

<script>
import Input from '@/components/form/Input'
import Button from '@/components/buttons/Button'
import Title from '@/components/texts/FormTitle'
import Counter from '@/components/counter/Counter'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'ResetPasswordValidate',

    components: {
        Input,
        Button,
        Title,
        Counter
    },

    data () {
        return {
            disabled: true,
            disabledExpiration: false,

            resetPasswordValidateLoading: false
        }
    },

    computed: {
        /** Get vuex state */
        ...mapGetters(['activationCodeResetPassword'])
    },

    methods: {
        /** Vuex actions. */
        ...mapActions(['resetPasswordValidateCode']),

        onInput (event) {
            if (event.length === 6) this.disabled = false
        },

        onSubmit () {
            this.resetPasswordValidateLoading = true

            this.resetPasswordValidateCode({
                email: localStorage.getItem('email'),
                reset_password_code: this.activationCodeResetPassword,
                sms_code: this.$refs.resetPasswordCodeForm.securityCode.value
            })
                .then(() => {
                    this.resetPasswordValidateLoading = false
                })
        }
    }
}
</script>
