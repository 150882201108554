<template>
    <div class="max-w-sm mx-auto mt-257 px-12 md:px-0">
        <Title
            :title="$t('resetPasswordFormTitle')"
            :subtitle="$t('resetPasswordFormSubtitle')"
        />

        <form
            @submit.prevent="onSubmit"
            ref="resetPasswordForm"
        >
            <!-- Email input -->
            <Input
                v-model="resetData.email"
                :error="errors.email"
                :class="[errors.email ? 'mb-4' : 'mb-22']"
                class="mb-40"
                :label="$t('resetPasswordEmailFieldLabel')"
                :placeholder="$t('resetPasswordEmailFieldPlaceholder')"
                type="email"
                name="email"
                isRequired="true"
            />

            <div class="w-full flex justify-center mb-36">
                <Button
                    class="button bg-purple-500 text-white hover:bg-purple-600"
                    role="submit"
                    :text="$t('resetPasswordButtonText')"
                    :disabled="resetPasswordInitLoading"
                    :spinning="resetPasswordInitLoading"
                />
            </div>
        </form>
    </div>
</template>

<script>
import Input from '@/components/form/Input'
import Button from '@/components/buttons/Button'
import Title from '@/components/texts/FormTitle'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'ResetPasswordInit',

    components: {
        Input,
        Button,
        Title
    },

    data () {
        return {
            resetPasswordInitLoading: false
        }
    },

    computed: {
        ...mapGetters(['resetData', 'errors'])
    },

    methods: {
        ...mapActions(['resetPasswordInit']),
        /** Reset Password */
        onSubmit () {
            this.resetPasswordInitLoading = true

            this.resetPasswordInit(this.resetData)
                .then(() => {
                    this.resetPasswordInitLoading = false
                })
        }
    }
}
</script>
